import React from 'react';
import { InView } from 'react-intersection-observer';
import { Motion, spring, OpaqueConfig } from 'react-motion';
import { styled, StyledProps } from '@glitz/react';

type PropType = {
  children: React.ReactNode;
};

type PlainStyleType<TValue> = {
  translateY: TValue;
  opacity: TValue;
};
type StylesType<TValue> = PlainStyleType<TValue>;

export default styled(({ compose, children, ...restProps }: StyledProps & PropType) => {
  return (
    <InView>
      {({ inView, ref, entry }) => (
        <Motion
          style={
            {
              translateY: spring(inView ? 0 : -40, { stiffness: 80, damping: 16 }),
              opacity: spring(inView ? 1 : 0, { stiffness: 16, damping: 7 }),
            } as StylesType<OpaqueConfig>
          }
        >
          {(interpolatedStyle: StylesType<number>) => (
            <styled.Div
              {...restProps}
              innerRef={ref}
              css={compose()}
              style={{
                ...(interpolatedStyle.translateY !== 0
                  ? {
                      transform: `translateY(${interpolatedStyle.translateY}px)`,
                      opacity: interpolatedStyle.opacity,
                    }
                  : null),
              }}
            >
              {children}
            </styled.Div>
          )}
        </Motion>
      )}
    </InView>
  );
});
